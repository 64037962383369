import { inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ContextOptions, Repository, RepositoryParams, RepositoryVersion } from '@app/core/repositories/repository';
import { HttpService } from '@app/core/repositories/http.service';
import {
  API_GATEWAY_URL,
  ID, Entities,
  TypeKitchen,
  TypeResponseData,
  TypeResponsePaginated,
  TypeCourier,
  TypeOrder,
  TypeOrderTransition,
  TypeOrdersFiltersMultiple,
  TypeOrdersFilters
} from '@app/shared';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class OmsRepository extends Repository {
  protected readonly version = RepositoryVersion.v1;
  protected readonly apiGatewayUrl = inject(API_GATEWAY_URL);
  protected readonly path = 'admin/oms';
  protected readonly contextOptions: Partial<ContextOptions> = {withoutCache: true};

  constructor(
    protected readonly http: HttpService,
  ) {
    super();
  }

  //[USE] GET    /v1/admin/oms/orders/{orderId}   Детальная информация о заказе
  public getOrder(orderId: ID<TypeOrder>): Observable<TypeResponseData<TypeOrder>> {
    return this.get<TypeResponseData<TypeOrder>>(`orders/${orderId}`);
  }

  public exportOrders(): Observable<Blob> {
    return this.http.post(this.apiGatewayUrl+'/v1/admin/oms/orders-export', {}, { 
      responseType: 'blob' as 'json' 
    } as any);
  }

  //[NOT] PATCH  /v1/admin/oms/orders/{orderId}   Обновление заказа
  public updateOrder(orderId: ID<TypeOrder>, order: TypeOrder): Observable<TypeResponseData<TypeOrder>> {
    return this.patch<TypeResponseData<TypeOrder>, TypeOrder>(`orders/${orderId}`, order);
  }

  //[USE] GET    /v1/admin/oms/orders   Информация о заказах
  public getOrders(options: TypeOrdersFilters): Observable<TypeResponsePaginated<Entities<TypeOrder>>> {
    //Todo: use sort
    const {page, limit, sort, ...filterParams} = options || {}, a: RepositoryParams = page && limit ? {page, limit} : {};
    const params: RepositoryParams = this.matchParamsAsFilter(a, filterParams);
    return this.get<TypeResponsePaginated<Entities<TypeOrder>>>('orders', params); //Verify paginated
  }

  //[USE] POST   /v1/admin/oms/orders-batch   Информация о заказах
  public ordersBatch(options: TypeOrdersFiltersMultiple): Observable<TypeResponsePaginated<Entities<TypeOrder>>> {
    const {page, limit, filter, sort, ...filterParams} = options || {}, params: RepositoryParams = page && limit ? {page, sort: sort ?? '', limit} : {};
    const {venueIds} = filterParams || {};
    // empty predefined response for empty filter value
    if (venueIds?.length === 0) {
      return of({data: [], meta: {page: 1, limit: 1000, total: 0}});
    }
    const data = filter ? {filter} : {filter: filterParams};// todo extract filters in parent
    return this.post<TypeResponsePaginated<Entities<TypeOrder>>, any>('orders-batch', data, params);
  }

  //[USE] GET    /v1/admin/oms/order-statuses   Доступные статусы заказа
  public orderStatuses(): Observable<TypeResponseData<Array<string>>> {
    return this.get<TypeResponseData<Array<string>>>('order-statuses'); //no params
  }

  //[USE] GET    /v1/admin/oms/orders/{orderId}/transitions   Информация об изменениях статуса заказа
  public orderTransitions(orderId: ID<TypeOrder>): Observable<TypeResponseData<Array<TypeOrderTransition>>> {
    return this.get<TypeResponseData<Array<TypeOrderTransition>>>(`orders/${orderId}/transitions`); //no params
  }

  //[USE] POST   /v1/admin/oms/order/{orderId}/assign-courier/{courierId}   Назначить курьера на заказ
  public orderAssignToCourier({id: orderId}: TypeOrder, {id: courierId, name, phone, deliveryMethod: transportType}: TypeCourier): Observable<HttpResponse<unknown>> {
    const data = {name, phone, transportType};
    const params = {observe: 'response', responseType: 'text'};
    return this.post(`order/${orderId}/assign-courier/${courierId}`, data, params);
  }

  //[USE] POST   /v1/admin/oms/order/{orderId}/cancel-courier/{courierId}   Снять курьера с заказа
  public orderCancelCourier(orderId: ID<TypeOrder>, courierId: ID<TypeCourier>): Observable<HttpResponse<unknown>> {
    const params = {observe: 'response', responseType: 'text'};
    return this.post(`order/${orderId}/cancel-courier/${courierId}`, {}, params);
  }

  public orderSetStatus(orderId: ID<TypeOrder>, orderStatus: string): Observable<HttpResponse<unknown>> {
    const params = {};
    return this.post(`order/${orderId}/set-status/${orderStatus}`, {}, params);
  }

  public orderSetItemStatus(orderId: ID<TypeOrder>, params: any): Observable<HttpResponse<unknown>> {
    return this.post(`orders/${orderId}/items-confirmation`, params, {});
  }

  public orderItemReturn(orderId: ID<TypeOrder>): Observable<HttpResponse<unknown>> {
    return this.post(`orders/${orderId}/items-return`, {}, {});
  }

  //[NOT] POST   /v1/admin/oms/carts/   Создать и обновить корзину
  //[NOT] GET    /v1/admin/oms/carts/{cartId}   Состав корзины пользователя
  //[NOT] POST   /v1/admin/oms/carts/from-order/{orderId}   Создать корзину по существующему заказу
  //[NOT] POST   /v1/admin/oms/carts/{customerId}/order   Создать заказ по корзине пользователя
  //[NOT] GET    /v1/admin/oms/orders-lite   Укороченная информация о заказах
  //[NOT] GET    /v1/admin/oms/orders-kds   Отформатированная информация о заказах для kds
  //[NOT] GET    /v1/admin/oms/orders-kds/{orderId}   Информация о заказе для kds
  //[NOT] POST   /v1/admin/oms/order/{orderId}/set-status/{orderStatus}   Установить статус заказа
  //[NOT] POST   /v1/admin/oms/orders/set-status/{orderStatus}   Установить статус заказов
  //[NOT] POST   /v1/admin/oms/order/{orderId}/add-cooking-time   Добавить время готовки
  //[NOT] GET    /v1/admin/oms/order/{orderId}/rate   Блюда заказа, уникальные, без модификаторов
  //[NOT] PUT    /v1/admin/oms/order/{orderId}/tax-receipt   Сохранение фискального чека
  //[NOT] POST   /v1/admin/oms/order/{orderId}/product/{productItemId}/change-status   Изменение статуса готовности блюда в заказе
  //[NOT] GET    /v1/admin/oms/order/{orderId}/discount-distribution   Размазка дисконтов заказа по продуктам
  //[NOT] POST   /v1/admin/oms/_debug/order/discount-distribution   Дебаг размазки

}
