import { inject, Injectable } from '@angular/core';
import { ContextOptions, Repository, RepositoryParams, RepositoryVersion } from '@app/core/repositories/repository';
import { HttpService } from '@app/core/repositories/http.service';
import {
  API_GATEWAY_URL, Entities, EntityPut,
  TypeCustomer,
  TypeCustomerAddress, TypeCustomersFilters,
  TypeResponseData,
  TypeResponsePaginated
} from '@app/shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrmRepository extends Repository {
  protected readonly version = RepositoryVersion.v1;
  protected readonly apiGatewayUrl = inject(API_GATEWAY_URL);
  protected readonly path = 'admin/crm';
  protected readonly contextOptions: Partial<ContextOptions> = {withoutCache: true};

  constructor(
    protected readonly http: HttpService,
  ) {
    super();
  }

  //[USE] GET    /v1/admin/crm/customers Получение Списка пользователей
  public getCustomers(options: TypeCustomersFilters): Observable<TypeResponsePaginated<Entities<TypeCustomer>>> {
    const {page, limit, ...filterParams} = options || {}, a: RepositoryParams = page && limit ? {page, limit} : {};
    const params: RepositoryParams = this.matchParamsAsFilter(a, filterParams, {skipEmpty: false}); // filter[isAnonymous]
    return this.get<TypeResponsePaginated<Entities<TypeCustomer>>>('customers', params); //Verify paginated
  }

  //[USE] GET    /v1/admin/crm/customers/{customerId} Получение данных пользователя
  public client(customerId: string): Observable<TypeResponseData<TypeCustomer>> {
    return this.get<TypeResponseData<TypeCustomer>>(`customers/${customerId}`);
  }

  //[USE] PUT    /v1/admin/crm/customers/{customerId} Обновление данных пользователя
  public updateClient(client: EntityPut<TypeCustomer>): Observable<TypeResponseData<TypeCustomer>> {
    const customerId = client.id;
    return this.put<TypeResponseData<TypeCustomer>, EntityPut<TypeCustomer>>(`customers/${customerId}`, client);
  }

  //[USE] GET    /v1/admin/crm/customers/{customerId}/addresses Список адресов пользователя
  public clientAddresses(customerId: string): Observable<TypeResponsePaginated<Array<TypeCustomerAddress>>> {
    return this.get<TypeResponsePaginated<Array<TypeCustomerAddress>>>(`customers/${customerId}/addresses`); //Verify params is not supported
  }

  //[USE] POST   /v1/admin/crm/customers/{customerId}/addresses Добавление адреса
  public addClientAddress(customerId: string, address: TypeCustomerAddress): Observable<TypeResponseData<TypeCustomerAddress>> {
    return this.post<TypeResponseData<TypeCustomerAddress>, TypeCustomerAddress>(`customers/${customerId}/addresses`, address);
  }
  //[USE] POST   /v1/admin/crm/customers/{customerId}/ban Бан Клиента
  public banClient(customerId: string): Observable<TypeResponseData<TypeCustomer>> {
    return this.post<TypeResponseData<TypeCustomer>, any>(`customers/${customerId}/ban`, null);
  }
  //[USE] POST   ​/v1​/admin​/crm​/customers​/{customerId}​/unban Розбан Клиента
  public unbanClient(customerId: string): Observable<TypeResponseData<TypeCustomer>> {
    return this.post<TypeResponseData<TypeCustomer>, any>(`customers/${customerId}/unban`, null);
  }

  //[NOT] GET    /v1/admin/crm/customers/{customerId}/addresses/{addressId} Получение адресов пользователя по id [DOC] todo: нет фильтров пагинации но в  ответе есть meta с total
  public clientAddress(customerId: string, addressId: string): Observable<TypeResponseData<Array<TypeCustomerAddress>>> {
    return this.get<TypeResponseData<Array<TypeCustomerAddress>>>(`customers/${customerId}/addresses/${addressId}`); //no params
  }

  //[USE] PUT    /v1/admin/crm/customers/{customerId}/addresses/{addressId} Редактирование адреса пользователя по id
  public updateClientAddress(customerId: string, address: TypeCustomerAddress): Observable<TypeResponseData<TypeCustomerAddress>> {
    return this.put<TypeResponseData<TypeCustomerAddress>, TypeCustomerAddress>(`customers/${customerId}/addresses/${address.id}`, address);
  }

  //[USE] DELETE /v1/admin/crm/customers/{customerId}/addresses/{addressId} Удаление адреса
  public deleteClientAddress(customerId: string, addressId: string): Observable<never> {
    return this.delete<never>(`customers/${customerId}/addresses/${addressId}`);
  }

  //[NOT] POST   /v1/admin/crm/customers Создание пользователя
  //[NOT] POST   /v1/admin/crm/customers/{customerId}/deactivate Деперсонализация пользователя
  //[NOT] POST   /v1/admin/crm/customers/{customerId}/deactivate Деперсонализация пользователя
  //[NOT] POST   /v1/admin/crm/customers/send-push Отправка PUSH пользователю

}
