import { HttpService } from '@app/core/repositories/http.service';
import {
  Repository,
  ContextOptions,
  RepositoryVersion,
} from '@app/core/repositories/repository';
import { API_GATEWAY_URL, TypeResponseData } from '@app/shared';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { inject, Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ImageRepository extends Repository {
  protected readonly version = RepositoryVersion.v1;
  protected readonly apiGatewayUrl = inject(API_GATEWAY_URL);
  protected readonly path = 'admin/pim/storage';
  protected readonly contextOptions: Partial<ContextOptions> = {
    withoutCache: true,
  };

  constructor(
    protected readonly http: HttpService,
    protected readonly httpClient: HttpClient,
  ) {
    super();
  }

  //[USE] POST   /v1/admin/images Загрузка изображения [DOC]
  public uploadImage(
    image: File,
  ): Observable<TypeResponseData<{ s3Key: string }>> {
    return from(this.convertToJpgIfNeeded(image)).pipe(
      switchMap((convertedImage) => {
        const formData = new FormData();
        formData.append('imagePath', convertedImage);
        return this.http.post<TypeResponseData<{ s3Key: string }>, FormData>(
          this.pathConstruct('upload-pre-sign'),
          formData,
          {
            context: this.setContextOptions({
              ...this.contextOptions,
              withoutCache: true,
            }),
          },
        );
      }),
    );
  }

  //[NOT] DELETE /v1/admin/images/{s3Key} Удаление изображения [DOC]
  public deleteImage(s3Key: string): Observable<never> {
    return this.delete<never>(`images/${s3Key}`);
  }

  async preSignUploadFile(
    file: File,
  ): Promise<{ title: string; path: string; url: string }> {
    const convertedFile = await this.convertToJpgIfNeeded(file);
    const { type, name } = convertedFile;
    const preSignResponse = await this.httpClient
      .post<any>(this.apiGatewayUrl + '/v1/admin/pim/storage/upload-pre-sign', {
        contentType: type,
        extension: name.split('.').pop(),
      })
      .toPromise();

    const { url, path } = preSignResponse;

    await this.uploadFileToPresignedUrl(url, convertedFile, type);

    return {
      title: name,
      path: path,
      url: url,
    };
  }

  private uploadFileToPresignedUrl(
    url: string,
    file: File,
    type: string,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url, true);
      xhr.setRequestHeader('Content-Type', type);

      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          const percentCompleted = Math.round((e.loaded * 100) / e.total);
          console.debug(`Upload "${file.name}" progress = ${percentCompleted}`);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve();
        } else {
          reject('Upload failed');
        }
      };

      xhr.onerror = () => {
        reject('Upload failed');
      };

      xhr.send(file);
    });
  }

  private async convertToJpgIfNeeded(file: File): Promise<File> {
    if (file.type === 'image/jpeg') {
      return file;
    }

    return new Promise<File>((resolve, reject) => {
      const image = new Image();
      const reader = new FileReader();
      reader.onload = (event: any) => {
        image.src = event.target.result;
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = image.width;
          canvas.height = image.height;
          if (context) {
            context.fillStyle = 'white';
            context.fillRect(0, 0, canvas.width, canvas.height);
          }
          context?.drawImage(image, 0, 0);
          canvas.toBlob(
            (blob) => {
              if (blob) {
                const convertedFile = new File(
                  [blob],
                  file.name.replace(/\.[^/.]+$/, '.jpg'),
                  { type: 'image/jpeg' },
                );
                resolve(convertedFile);
              } else {
                reject(new Error('Conversion to JPG failed'));
              }
            },
            'image/jpeg',
            0.95,
          );
        };
        image.onerror = (err) => reject(err);
      };
      reader.onerror = (err) => reject(err);
      reader.readAsDataURL(file);
    });
  }
}
